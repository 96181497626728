import {
  Container,
  Button,
  Grid,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import logo from "../assets/logo.png";

import { AiOutlineEyeInvisible, AiOutlineEye } from "react-icons/ai";
import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Img from "../assets/LogoImg.jpg";
import Logo from "./Logo";

const Login = () => {
  const navigate = useNavigate();
  const [values, setValues] = useState({
    email: "",
    pass: "",
    showPass: false,
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("https://api.foodyno.gomaplus.tech/api/login", {
        email: values.email,
        password: values.pass,
      })
      .then((res) => {
        localStorage.setItem("token", res.data.token);

        localStorage.setItem("branch_id", res.data.user.branch.id);

        console.log(res);
        if (res.status === 200) {
          navigate("/dashboard");
        }

        console.log(res.status);
      })
      .catch((err) => console.error(err));
  };
  const handlePassVisibilty = () => {
    setValues({
      ...values,
      showPass: !values.showPass,
    });
  };

  return (
    <div className="grid lg:grid-cols-2 grid-cols-1 bg-white">
      <div className="lg:w-full w-10/12 mx-auto">
        <Container maxWidth="lg">
          <Grid
            container
            spacing={2}
            direction="column"
            justifyContent="center"
            style={{ minHeight: "100vh" }}
          >
            <Logo />
            <h1 className="text-xl text-zinc-700 text-center py-6 mb-10 font-semibold">
              Welcome to waiter{" "}
            </h1>
            <form onSubmit={handleSubmit}>
              <Grid container direction="column" spacing={4}>
                <Grid item>
                  <TextField
                    type="email"
                    fullWidth
                    label="Enter your email"
                    placeholder="Email Address"
                    variant="outlined"
                    required
                    onChange={(e) =>
                      setValues({ ...values, email: e.target.value })
                    }
                  />
                </Grid>

                <Grid item>
                  <TextField
                    type={values.showPass ? "text" : "password"}
                    fullWidth
                    label="Password"
                    placeholder="Password"
                    variant="outlined"
                    required
                    onChange={(e) =>
                      setValues({ ...values, pass: e.target.value })
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            onClick={handlePassVisibilty}
                            aria-label="toggle password"
                            edge="end"
                          >
                            {values.showPass ? (
                              <AiOutlineEyeInvisible />
                            ) : (
                              <AiOutlineEye />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <button
                  className="rounded-sm text-white mx-auto my-10"
                  type="submit"
                  variant="contained"
                  style={{
                    backgroundImage: `linear-gradient(to right, #d22afc, #a758ed,#7073de)`,
                    fontWeight: "bold",
                    width: "50%",
                    padding: "8px",
                  }}
                >
                  LOG IN
                </button>
              </Grid>
            </form>
          </Grid>
        </Container>
      </div>
      <div className=" hidden lg:flex items-center justify-center relative">
        <img
          src={Img}
          alt=""
          className="max-h-screen w-full object-cover  object-center opacity-70"
        />
        <img
          src={logo}
          alt="ml"
          style={{ width: "200px" }}
          className="mx-auto  absolute"
        />
      </div>
    </div>
  );
};

export default Login;
