import React, { useEffect, useState } from "react";
import { BiLogOut } from "react-icons/bi";
import {
  Box,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Stack,
  Typography,
  Button,
} from "@mui/material";
import Logo from "./Logo";
import Order from "./Order";

import Pusher from "pusher-js";
import { useDispatch } from "react-redux";
import Notifications from "./Notifications";
import { request } from "../api/request";
import Tables from "./Tables";
import Loader from "./loader/loader";
import { addNotification } from "../Redux/Slices/NotificationSlice";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

function Dashboard() {
  const dispatch = useDispatch();
  const [ordersInfo, setOrders] = useState({
    error: {},
    isLoading: false,
    orders: [],
  });

  const getOrders = () => {
    setOrders((prev) => {
      return { ...prev, isLoading: true };
    });
    request({
      url: `/orders/waiter/${localStorage.getItem("branch_id")}`,
    })
      .then((resp) => {
        console.log(resp, "resp");
        setOrders((prev) => {
          return { ...prev, isLoading: false, orders: resp?.data?.data };
        });
      })
      .catch((err) => {
        console.log(err);
        setOrders((prev) => {
          return { ...prev, isLoading: false, error: err };
        });
      });
  };

  useEffect(() => {
    getOrders();
  }, []);

  // Ready Orders realtime
  useEffect(() => {
    const pusher = new Pusher("1604fd4ad916b0f5ed4d", {
      cluster: "mt1",
    });

    const channel = pusher.subscribe(
      `Waiter.${localStorage.getItem("branch_id")}`
    );

    channel.bind("ToWaiter", (data) => {
      console.log(data, "TAGBEGVSDGVDFSGADFGD");
      const order = data?.Waiter;
      order.isNew = true;
      order.table = { table_num: order.table_id };
      dispatch(addNotification({ type: "new", order_id: order.id }));
      setOrders((prev) => {
        return { ...prev, orders: [order, ...prev.orders] };
      });
    });

    return () => {
      channel.unbind();
      pusher.disconnect();
    };
  }, []);

  const getWaiters = useQuery({
    queryKey: ["get-all-waiters"],
    queryFn: () => {
      return request({
        url: `/waiter/branch/${localStorage.getItem("branch_id")}`,
      });
    },
  });
  const navigate = useNavigate();
  const logoutHandler = () => {
    localStorage.removeItem("token");
    navigate("/");
  };
  const [waiterName, setWaiterName] = React.useState("");

  const handleChange = (event) => {
    setWaiterName(event.target.value);
    localStorage.setItem("waiter_name", event.target.value);
  };

  useEffect(() => {
    if (getWaiters.isSuccess) {
      console.log('getWaiters',getWaiters)
      if (getWaiters.data.data.data.length !== 0)
        setWaiterName(getWaiters.data.data.data[0].name);
      localStorage.setItem("waiter_name", getWaiters?.data.data.data[0]?.name);
    }
  }, [getWaiters.isSuccess]);

  return (
    <Grid container className="bg-slate-100">
      <Grid item lg={9} md={9} sm={10} xs={10}>
        <Box
          sx={{
            minHeight: "100vh",
            padding: "0rem 0rem",
          }}
        >
          <Notifications />
          {/* <Tables /> */}
          <div
            style={{
              boxShadow: "2px 0 21px 1px #d4d4d8",
              padding: "1rem 0rem",
            }}
          >
            <Stack
              alignItems={"center"}
              direction={"row"}
              justifyContent={"space-between"}
              paddingX={"2rem"}
            >
              {" "}
              <button
                onClick={logoutHandler}
                variant="contained"
                style={{
                  marginTop: "1rem",
                  borderRadius: "3px",
                  border: 0,
                  color: "#a758ed",
                  height: "40px",
                }}
              >
                <BiLogOut size={30} />
              </button>
              <Logo />
              {getWaiters.isLoading ? (
                <Skeleton width={"120px"} height={"70px"} animation="wave" />
              ) : (
                <FormControl
                  variant="standard"
                  sx={{ m: 1, paddingTop: "1.5rem", minWidth: 120 }}
                >
                  {/* <InputLabel id="demo-simple-select-standard-label">
                  I am
                </InputLabel> */}

                  <Select
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={waiterName}
                    onChange={handleChange}
                    label="I am"
                  >
                    {getWaiters.data.data.data.map((item) => {
                      return <MenuItem value={item.name}>{item.name}</MenuItem>;
                    })}
                  </Select>
                </FormControl>
              )}
            </Stack>
          </div>

          {ordersInfo.isLoading ? (
            <Loader />
          ) : (
            <Grid item lg={10}>
              <Grid spacing={1} justifyContent={"start"} container>
                {ordersInfo.orders?.map((order, i) => {
                  return (
                    <Grid key={i} item md={6} xs={12}>
                      <Order setLoading={setOrders} order={order} />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          )}
        </Box>
      </Grid>
      <Grid item lg={3} md={3} sm={2} xs={2}>
        {" "}
        <Box
          sx={{
            background: "#ddd",
            position: "sticky",
            top: "0",
            right: "0",
            width: "100%",
            height: "100vh",
          }}
        >
          <Tables />
        </Box>
      </Grid>
    </Grid>
  );
}

export default Dashboard;
