import React, { useState } from "react";
import { Box, Button, Paper, Stack } from "@mui/material";
import SubOrder from "./SubOrder";
import Timer from "./Timer";
import { green } from "@mui/material/colors";
import { request } from "../api/request";
// import Timer from "./Timer";

const Order = ({ order, setLoading }) => {
  const [state, setState] = useState(order.status);
  let color;
  if (state === "new") {
    color = "#c241f3";
  } else if (state === "change") {
    color = "#6ab4d9";
  } else {
    color = "#c241f3";
  }

  const deliverOrder = () => {
    setLoading((prev) => {
      return { ...prev, isLoading: true };
    });
    request({
      url: `/orders/done/${order.id}`,
      method: "POST",
      data: { waiter_name: `${localStorage.getItem("waiter_name")}` },
    })
      .then((resp) => {
        console.log(resp, "resp");
        setLoading((prev) => {
          return { ...prev, isLoading: false };
        });
        window.location.reload()
      })
      .catch((err) => {
        console.log(err);
        setLoading((prev) => {
          return { ...prev, isLoading: false };
        });
      });
  };

  return (
    <Box sx={{ padding: "0.2rem" }}>
      <Paper
        sx={{
          padding: "1rem",
          borderRadius: "10px",
          border: `${order.isNew ? "3px solid" : "none"}`,
          borderColor: green["200"],
          boxShadow: order.isNew ? `0 2px 18px 3px ${green["300"]}` : "none",
        }}
      >
        <Stack
          p={1}
          alignItems={"center"}
          justifyContent={"space-between"}
          direction={"row"}
          gap={3}
        >
          <Box sx={{ fontWeight: "bold" }}>Table : {order.table.table_num}</Box>
          <Box sx={{ fontWeight: "bold" }}>Order id : {order.id}</Box>
        </Stack>
        <Stack
          flexWrap={"wrap"}
          p={1}
          gap={2}
          alignItems={"center"}
          justifyContent={"space-between"}
          direction={"row"}
        >
          <Box
            sx={{
              background: color,
              padding: "0.5rem 4rem",
              color: "#fff",
              fontWeight: "bold",
              borderRadius: "0.5rem",
            }}
          >
            New
          </Box>
          <Box sx={{ fontWeight: "bold" }}>
            Order time : <span style={{ color: "#c4c4c4" }}>{order.time}</span>
          </Box>
        </Stack>
        {order.products.map((item, index) => {
          return <SubOrder color={color} key={index} subOrder={item} />;
        })}
      </Paper>
      <Box>
        <Button
          style={{
            color: "#fff",
            fontWeight: "bold",
            backgroundColor: color,
            width: "100%",
            outline: "none",
            border: "none",
            padding: "0.7rem ",
            margin: "1rem 0  0",
            borderRadius: "5px",
            transition: "0.2s",
          }}
          onClick={deliverOrder}
        >
          make it delivered
        </Button>
      </Box>
    </Box>
  );
};

export default Order;
