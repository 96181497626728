import { Box, Typography, Grid, Stack, Button } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { request } from "../api/request";
import { useNavigate } from "react-router-dom";
import Pusher from "pusher-js";
import Heading from "./Heading";

const Tables = () => {
  const [help, setHelp] = useState([]);
  const getTables = useQuery({
    queryKey: ["get-tables"],
    queryFn: () => {
      return request({
        url: `/table/branch/${localStorage.getItem("branch_id")}`,
      });
    },
  });

  const navigate = useNavigate();

  useEffect(() => {
    const pusher = new Pusher("1604fd4ad916b0f5ed4d", {
      cluster: "mt1",
    });

    const channel = pusher.subscribe(
      `waiter.${localStorage.getItem("branch_id")}`
    );

    channel.bind("CallWaiter", (data) => {
      console.log('data',data)
      setHelp((prev) => {
        return [...prev, data?.table.id];
      });
    });

    return () => {
      channel.unbind();
      pusher.disconnect();
    };
  }, []);

  console.log(help);

  if (getTables.isLoading) {
    return <Typography>loading...</Typography>;
  }
  const tables = getTables.data?.data.data;
  return (
    <div className="bg-slate-200 min-h-screen ">
      <Box style={{ padding: "2rem 0rem" }}>
        <Heading width={"40%"} size={"1.5rem"}>
          Tables
        </Heading>
        <Grid justifyContent={"center"} alignItems={"center"} container>
          {tables.map((item) => {
            return (
              <Grid sx={{ padding: "1rem" }} item lg={12}>
                <Stack
                  sx={{
                    border: "1px solid #6664de",
                    borderRadius: "3px",
                    padding: "0.5rem",
                  }}
                  direction={"row"}
                  justifyContent={"space-between"}
                >
                   <a href={`https://user-v2.restu-plus.com/?table_id=${item?.id} `} target="_blank">
                  <Button
                    className={`${
                      help.includes(item.id) ? "tableAnimation" : ""
                    }`}
                    variant="contained"
                    sx={{
                      textAlign: "center",
                      background: "#6664de",
                      padding: "0.2rem 4rem",
                      color: "white",

                      margin: "auto auto",
                    }}
                    onClick={() => {
                      navigate("/");
                    }}
                  >
                    {item.table_num}
                  </Button>
                  </a>
                  {help.includes(item.id) && (
                    <Button
                      sx={{
                        padding: "0",
                        fontSize: "0.8rem",
                      }}
                      onClick={() => {
                        setHelp((prev) => {
                          const newArr = prev.filter((table) => {
                            return table !== item.id;
                          });
                          return newArr;
                        });
                      }}
                    >
                      serve
                    </Button>
                  )}
                </Stack>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </div>
  );
};

export default Tables;
